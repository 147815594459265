export const environment = {
  name: 'prod',
  version: 'v1-271-0',
  sentry: {
    dsn: 'https://8e8579440b241f7beca527816cf959a3@o4506207716114432.ingest.sentry.io/4506581323153408',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    sendClientReports: false,
    showDialog: false,
  },
  production: true,
  admin: {
    url: 'https://admin.freddy.ovh',
  },
  firebase: {
    region: 'europe-west1',
    databaseURL:
      'https://freddy-prod-default-rtdb.europe-west1.firebasedatabase.app/',
    apiKey: 'AIzaSyDrVsDjetv4CAtOGesgTFV5C7UZXL0SzM8',
    authDomain: 'game.freddy.ovh',
    projectId: 'freddy-prod',
    storageBucket: 'freddy-prod.appspot.com',
    messagingSenderId: '726871418891',
    appId: '1:726871418891:web:d19324d8b6d9f35f5b3f0e',
    measurementId: 'G-L5H5SJ6Y3C',
    recaptcha: '6Ld7jxYqAAAAACuU6wfebxko4byvpqANYJ9Vx9jL',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNseXloZ3FobzI4djcycXNoYWdhajA0dngifQ.PgA8mAMfTXoI4zYrNVXrQw',
    geocoder: {
      countriesActivated: 'fr,nl,be',
    },
    style: 'mapbox://styles/bokzor/clzzip5s8005v01phbvfvddax',
    preload: {
      enabled: true,
    },
    defaultZoom: 18,
    minZoom: 13,
    maxZoom: 19,
  },
};
